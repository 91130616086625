import CreditorStatus from '@/modules/Admin/statics/creditorStatus';
import DebtorAssignmentStatus from '@/modules/Admin/statics/debtorAssignmentStatus';
import RequestStatus from '@/modules/Admin/statics/requestStatus';
import { mapAddress, mapTypeRelatedDebtorData } from '@/shared/models';
import { MILLISECONDS_PER_DAY } from '@/statics/timePeriods';
import {
  mapCreditorPermissions,
  mapServiceFeeCondition,
  mapServiceFeeMatrix
} from '@/shared/models';

export const mapUsers = (users) =>
  users.map((user) => ({
    id: user.id,
    status: user.status,
    creditorName: user.name,
    userName: user.username,
    userActive: user.active,
    sapNumber: user.sapNumber,
    city: user.city,
    email: user.email,
    roles: user.roles,
    product: user.product,
    permissions: user.permissions,
    isSentToSap: user.isSendToSap
  }));

export const mapCreditors = (creditors) =>
  creditors.map((creditor) => ({
    id: creditor.identifier,
    status: creditor.status,
    created: creditor.creationDate * 1000,
    updated: creditor.updateDate * 1000,
    creditorName: creditor.name,
    errorCount: creditor.apiErrorCount,
    sapNumber: creditor.sapNumber,
    city: creditor.city,
    email: creditor.email,
    product: creditor.product,
    hasOpenSepaUpdateRequest: creditor.hasOpenCreditorDebitUpdate,
    isSentToSap: creditor.isSendToSap,
    isBlocked: creditor.status === CreditorStatus.BLOCKED,
    isTerminated: creditor.status === CreditorStatus.TERMINATED,
    setUpFee: creditor.setupFee,
    setUpFeeCharged: creditor.isSetupFeeExists,
    permissions: mapCreditorPermissions(creditor.permission),
    doc2PortalEnabled: creditor.invoicesEnabled,
    letter2PostEnabled: creditor.documentsEnabled,
    ...(creditor.user
      ? {
          userId: creditor.user.id,
          userName: creditor.user.username,
          userRoles: creditor.user.roles,
          userEmail: creditor.user.email,
          userActive: creditor.user.active
        }
      : {
          userId: '',
          userName: '',
          userRoles: '',
          userEmail: ''
        })
  }));

export const mapRequests = (list) =>
  list.map((entry) => ({
    id: entry.identifier,
    status: entry.status,
    creditor: {
      id: entry.creditor.identifier,
      name: entry.creditor.name,
      sapNumber: entry.creditor.sapNumber,
      product: entry.creditor.product
    },
    sepaMandate: {
      accountOwner: entry.debitData.accountOwner,
      iban: entry.debitData.iban,
      start: entry.debitData.startSepaDate * 1000
    },
    creator: {
      id: entry.creator.identifier,
      name: entry.creator.name
    },
    created: entry.creationDate * 1000,
    decided: entry.decisionDate * 1000,
    decisionUser: entry.decisionUser,
    isOpen: entry.status === RequestStatus.OPEN
  }));

export const mapAdminUsers = (users) =>
  users.map((user) => ({
    id: user.id,
    userName: user.username,
    userActive: user.active,
    email: user.email,
    role: user.role,
    product: user.product,
    permissions: user.permissions
  }));

export const mapDebtorAssignmentList = (list) =>
  list.map((item) => ({
    id: item.identifier,
    status: item.status,
    isOpen: item.status === DebtorAssignmentStatus.OPEN,
    creationDate: item.creationDate * 1000,
    creditor: {
      id: item.creditor.identifier,
      name: item.creditor.name,
      sapNumber: item.creditor.sapNumber,
      productType: item.creditor.productType
    },
    voucher: item.voucher
      ? {
          customNumber: item.voucher.customNumber,
          bnetId: item.voucher.bnetIdentifier
        }
      : null,
    address: {
      street: item.addresses.street,
      zip: item.addresses.zip,
      city: item.addresses.city
    }
  }));

export const mapDebtorAssignmentEntry = (item) => ({
  id: item.identifier,
  status: item.status,
  isOpen: item.status === DebtorAssignmentStatus.OPEN,
  isDebtorRequest: !item.voucher,
  debtorRequestDate: item.debtorRequest?.requestDate * 1000,
  manualDecisionReason: item.manualDecisionReason,
  creditor: {
    id: item.creditor.identifier,
    name: item.creditor.name,
    sapNumber: item.creditor.sapNumber,
    productType: item.creditor.productType,
    creationDate: item.creditor.creationDate * 1000,
    contractStartDate: item.creditor.contractStart * 1000,
    address: {
      street: item.creditor.address.street,
      zip: item.creditor.address.zip,
      city: item.creditor.address.city
    },
    contact: {
      firstName: item.creditor.contact.contactPerson.firstName,
      lastName: item.creditor.contact.contactPerson.lastName,
      email: item.creditor.contact.email,
      phone: item.creditor.contact.telephoneNumber
    }
  },
  addresses: {
    normalizedAddress: mapAddress(item.addresses.normalizedAddress),
    limitAddress: {
      ...mapAddress(item.addresses.limitAddress),
      email: item.addresses.limitAddress.email,
      phone: item.addresses.limitAddress.telephone
    }
  },
  voucher: item.voucher
    ? {
        id: item.voucher.identifier,
        status: item.voucher.status,
        statusToShow: item.voucher.statusToShow,
        type: item.voucher.type,
        creationDate: item.voucher.creationDate * 1000,
        uploadDate: item.voucher.uploadedDate * 1000,
        voucherDate: item.voucher.voucherDate * 1000,
        paymentDeadline: item.voucher.paymentDeadline * 1000,
        customNumber: item.voucher.customNumber,
        bnetId: item.voucher.bnetIdentifier,
        documentId: item.voucher.documentIdentifier,
        sapNumber: item.voucher.sapNumber,
        amount: item.voucher.amount,
        customDebtorNumber: item.voucher.customDebtorNumber,
        source: item.voucher.source,
        deliveryType: item.voucher.deliveryType,
        email: item.voucher.email
      }
    : null,
  debtors: item.debtors.map((debtor) => ({
    ...mapTypeRelatedDebtorData(debtor),
    id: debtor.identifier,
    status: debtor.status,
    sapNumber: debtor.sapNumber,
    cspIdentifier: debtor.cspIdentifier,
    creationDate: debtor.creationDate * 1000,
    address: mapAddress(debtor.limitAddress),
    limit: {
      approvedLimit: debtor.businessRelationship.approvedLimit,
      availableLimit: debtor.businessRelationship.availableLimit,
      expirationDate: debtor.businessRelationship.limitExpiredDate * 1000,
      limitUpdated: debtor.businessRelationship.limitUpdateDate * 1000
    },
    invoices: {
      uploadedInvoicesCount: debtor.invoices.uploadedInvoicesCount,
      openInvoicesCount: debtor.invoices.openInvoiceCount,
      firstInvoiceUploadDate: debtor.invoices.firstInvoiceUploadDate * 1000,
      lastInvoiceUploadDate: debtor.invoices.lastInvoiceUploadDate * 1000
    },
    locations: debtor.locations.map((location) => ({
      id: location.identifier,
      name: location.name,
      status: location.status,
      contactPerson: location.contactPerson,
      customNumber: location.customDebtorNumber,
      deliveryType: location.deliveryType,
      email: location.email,
      address: mapAddress(location.normalizedAddress),
      invoices: {
        uploadedInvoicesCount: location.invoices.uploadedInvoicesCount,
        openInvoicesCount: location.invoices.openInvoiceCount,
        firstInvoiceUploadDate: location.invoices.firstInvoiceUploadDate * 1000,
        lastInvoiceUploadDate: location.invoices.lastInvoiceUploadDate * 1000
      }
    }))
  }))
});

export const mapActionLogList = (list) =>
  list.map((item) => ({
    id: item.identifier,
    userId: item.userIdentifier,
    translatedActionType: item.actionType,
    productType: item.productType,
    payload: item.payload,
    adminEmail: item.extra.adminEmail,
    userEmail: item.extra.userEmail,
    creditorSapNumber: item.filterCreditorSapNumber,
    debtorSapNumber: item.filterDebtorSapNumber,
    voucherSapNumber: item.filterVoucherSapNumber,
    voucherReferenceNumber: item.filterVoucherReference,
    transactionKey: item.filterTransactionKey,
    creationDate: item.creationDate * 1000,
    rawData: item
  }));

export const mapServiceFeeMatrixList = (list) =>
  list
    .map((matrixEntry) => mapServiceFeeMatrix(matrixEntry))
    .sort((entry) => (entry.isDefault ? -1 : 1));

export const mapCreditorServiceFeeConditions = (list) =>
  list
    .sort((a, b) => b.validFromDate - a.validFromDate)
    .map((condition, index) => ({
      ...mapServiceFeeCondition(condition),
      endDate: index > 0 ? list[index - 1].validFromDate * 1000 - MILLISECONDS_PER_DAY : null
    }));
