<template>
  <v-chip :color="color" :outlined="outlined" :dark="!outlined" class="caption">
    {{ $te(options.label) ? $t(options.label) : $t('unknown') }}
  </v-chip>
</template>

<script>
import CreditorStatus from '@/modules/Admin/statics/creditorStatus';
import DebtorStatus from '@/modules/Debtors/statics/debtorStatus';
import PermanentDebtStatus from '@/modules/PermanentDebts/statics/permanentDebtStatus';
import UserStatus from '@/modules/Admin/statics/userStatus';
import VoucherSubStatus from '@/statics/voucherSubStatus';

export default {
  name: 'AppStatusChip',

  props: {
    value: {
      type: [Number, String],
      default: ''
    }
  },

  data: () => ({
    outlined: true,
    color: 'grey'
  }),

  computed: {
    options() {
      switch (this.$route.name) {
        case this.routeName.CREDITORS:
          return {
            states: [
              { value: CreditorStatus.ACTIVE, outlined: true, color: 'success' },
              { value: CreditorStatus.BLOCKED, outlined: true, color: 'warning' },
              { value: CreditorStatus.TERMINATED, outlined: true, color: 'error' }
            ],
            label: `creditors.statusOptions.${this.value}`
          };

        case this.routeName.DEBTORS:
          return {
            states: [
              { value: DebtorStatus.ACTIVE, outlined: true, color: 'success' },
              { value: DebtorStatus.INACTIVE, outlined: true, color: 'error' },
              { value: DebtorStatus.REACTIVATED, outlined: true, color: 'success' }
            ],
            label: `debtors.statusOptions.${this.value}`
          };

        case this.routeName.PERMANENT_DEBTS:
          return {
            states: [
              { value: PermanentDebtStatus.ACTIVE, outlined: true, color: 'success' },
              { value: PermanentDebtStatus.CREATED, outlined: true, color: 'grey' },
              { value: PermanentDebtStatus.EXPIRED, outlined: true, color: 'error' },
              { value: PermanentDebtStatus.STOPPED, outlined: true, color: 'error' }
            ],
            label: `permanentDebts.statusOptions.${this.value}`
          };

        case this.routeName.USERS:
          return {
            states: [
              { value: UserStatus.ACTIVE, outlined: true, color: 'success' },
              { value: UserStatus.BLOCKED, outlined: true, color: 'warning' },
              { value: UserStatus.PASSWORD_RESET, outlined: true, color: 'success' },
              { value: UserStatus.PASSWORD_RESET_REQUESTED, outlined: true, color: 'success' },
              { value: UserStatus.NEW, outlined: true, color: 'grey' },
              { value: UserStatus.DEACTIVATED, outlined: true, color: 'error' },
              { value: UserStatus.TERMINATED, outlined: true, color: 'error' }
            ],
            label: `users.statusOptions.${this.value}`
          };

        case this.routeName.VOUCHERS:
          return {
            states: [
              { value: VoucherSubStatus.ACCEPTED, outlined: true, color: 'grey' },
              { value: VoucherSubStatus.COUNTED_BACK, outlined: true, color: 'primary' },
              { value: VoucherSubStatus.COUNT_BACK, outlined: true, color: 'warning' },
              { value: VoucherSubStatus.CUSTOMER_COMPLAINT, outlined: true, color: 'primary' },
              { value: VoucherSubStatus.DELAYED, outlined: true, color: 'primary' },
              { value: VoucherSubStatus.DEFERRED_PAYMENT, outlined: true, color: 'primary' },
              { value: VoucherSubStatus.DUNNING, outlined: false, color: 'error' },
              { value: VoucherSubStatus.ENCASHMENT, outlined: false, color: 'error' },
              { value: VoucherSubStatus.HALT, outlined: false, color: 'error' },
              { value: VoucherSubStatus.IN_REVIEW, outlined: true, color: 'grey' },
              { value: VoucherSubStatus.NOT_PURCHASABLE, outlined: true, color: 'primary' },
              { value: VoucherSubStatus.NOT_PURCHASABLE_ANYMORE, outlined: true, color: 'primary' },
              { value: VoucherSubStatus.NOT_PURCHASED, outlined: true, color: 'primary' },
              { value: VoucherSubStatus.PAYMENT_REMINDER, outlined: false, color: 'orange' },
              { value: VoucherSubStatus.PURCHASABLE, outlined: true, color: 'primary' },
              { value: VoucherSubStatus.PURCHASED, outlined: true, color: 'success' },
              { value: VoucherSubStatus.PURCHASED_LATER, outlined: true, color: 'success' },
              { value: VoucherSubStatus.PURCHASED_MANUALLY, outlined: true, color: 'success' },
              { value: VoucherSubStatus.UNVERIFIED, outlined: true, color: 'grey' }
            ],
            label: `vouchers.subStatusOptions.${this.value}`
          };

        default:
          return { states: [], label: '' };
      }
    }
  },

  watch: {
    value: {
      handler() {
        const actualState = this.options.states.find((status) => status.value === this.value);
        if (!actualState) {
          return;
        }
        this.outlined = actualState.outlined;
        this.color = actualState.color;
      },
      immediate: true
    }
  }
};
</script>

<style scoped lang="scss">
#app.v-application .v-chip {
  height: unset;
  padding: 0.2rem 0.5rem;
  min-width: 80px;
  justify-content: center;

  &.v-chip--outlined {
    background-color: white !important;
  }

  &::before {
    opacity: 0;
  }
}

body
  #app.v-application
  .theme--light.v-data-table.app-data-table
  tbody
  tr.data-table-row--highlighted
  .v-chip.grey--text {
  color: var(--c-grey) !important;
}
</style>
