<template>
  <div>
    <v-list-item dense class="user-menu-content__prepend flex-column justify-center overline">
      <app-tooltip
        v-if="$auth.user().isBlocked || $auth.user().isTerminated"
        :text="$auth.user().isBlocked ? 'user.hintBlocked' : 'user.hintTerminated'"
      >
        <span class="text-uppercase error--text semi-bold d-flex align-center">
          - {{ $auth.user().status | formatCreditorStatus }} -
          <icon-info class="ml-2" height="14" width="14" />
        </span>
      </app-tooltip>

      <template v-if="$auth.user().sapNumber">
        {{ $t('user.myCustomerId') }}: {{ $auth.user().sapNumber }}
      </template>
    </v-list-item>
    <template v-for="(item, index) in items">
      <v-list-item
        v-if="hasFulfilledCondition(item)"
        :key="index"
        dense
        class="subtitle-1"
        :disabled="!item.action && !item.to"
        @click="item.action || item.to ? onItemSelect({ action: item.action, to: item.to }) : null"
      >
        <v-list-item-content :class="item.color ? `${item.color}--text` : null">
          {{ $t(item.title) }}
        </v-list-item-content>
      </v-list-item>
    </template>
  </div>
</template>

<script>
export default {
  name: 'UserMenuContent',

  props: {
    items: {
      type: Array,
      required: true
    }
  },

  methods: {
    onItemSelect({ action, to }) {
      if (action) {
        action();
      }

      if (to && to !== this.$route.name) {
        this.$router.push({ name: to });
      }

      this.$emit('close', true);
    }
  }
};
</script>

<style scoped>
.user-menu-content__prepend::after {
  content: unset;
}
</style>
