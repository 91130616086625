import FAQLinks from '@/modules/User/statics/faqLinks';
import ProductType from '@/statics/productType';
import i18n from '@/helper/i18n';
import { BASIC_VOUCHER_STATUS_TO_SHOW_GROUPS } from '@/statics/voucherStatusToShow';
import { BASIC_VOUCHER_SUB_STATUS_FILTER_OPTIONS } from '@/statics/voucherSubStatus';
import { VoucherType, BASIC_VOUCHER_TYPE_GROUPS } from '@/statics/voucherType';
import {
  CreditorVoucherType,
  BASIC_CREDITOR_VOUCHER_TYPE_GROUPS
} from '@/modules/User/statics/creditorVoucherType';
import { DEGENER_PERMISSIONS } from '@/statics/productPermissions';
import {
  IMPRINT_FINTECRITY,
  PRIVACY_POLICY_FINTECRITY,
  PRODUCT_INFO_DEGENER,
  SERVICE_HOTLINE_FINTECRITY,
  SERVICE_MAIL_FINTECRITY
} from '@/statics/customerServiceInformation';
import {
  ASSET_CONSULTANT,
  ASSET_CONSULTANT_NUMBER,
  CREDITOR_LIMIT,
  DEPARTMENT,
  FACTORABLE_TURNOVER,
  FOUNDING_DATE,
  INTERMEDIARY_NUMBER,
  ONLINE_TURNOVER_RATE,
  VOB
} from '@/helper/createCreditorFactory';

const PRODUCT_NAME = 'FSO360 Cash';

const DAA_BILLING_CONFIG = {
  type: ProductType.DAA_BILLING,
  productName: PRODUCT_NAME,
  logo: require('@/assets/DEGENER-logo-small.svg'),
  partnerLogo: require('@/assets/FinTecrity-logo.svg'),
  scaleLogosByWidth: true,
  favIcon: '/favicons/faviconDegener.ico',
  theme: 'fintecrity' /* matches key of $theme-fintecrity in styles/themes/index.scss */,
  background: null,
  placeLogoInHeader: false,
  serviceHotline: SERVICE_HOTLINE_FINTECRITY,
  serviceMail: SERVICE_MAIL_FINTECRITY,
  infoUrl: PRODUCT_INFO_DEGENER,
  privacyPolicyUrl: PRIVACY_POLICY_FINTECRITY,
  imprintUrl: IMPRINT_FINTECRITY,
  withPurchaseAssurance: true,

  voucherStatusToShowGroups: BASIC_VOUCHER_STATUS_TO_SHOW_GROUPS,
  voucherSubStatusFilter: BASIC_VOUCHER_SUB_STATUS_FILTER_OPTIONS,
  voucherTypeGroups: {
    ...BASIC_VOUCHER_TYPE_GROUPS,
    // additionally supported voucher types
    [VoucherType.PURCHASE_RETIREMENT]: VoucherType.PURCHASE_RETIREMENT,
    // no support for permanent debt invoices
    [VoucherType.PERMANENT_DEBT_INVOICE]: []
  },
  creditorVoucherTypeGroups: {
    ...BASIC_CREDITOR_VOUCHER_TYPE_GROUPS,
    // additionally supported creditor voucher types
    [CreditorVoucherType.ACCOUNTS_RECEIVABLE]: [CreditorVoucherType.ACCOUNTS_RECEIVABLE],
    [CreditorVoucherType.REVERSED_TRANSACTION]: [CreditorVoucherType.REVERSED_TRANSACTION]
  },

  permissions: DEGENER_PERMISSIONS,

  onboarding: {
    blacklist: [
      ONLINE_TURNOVER_RATE,
      FACTORABLE_TURNOVER,
      CREDITOR_LIMIT,
      FOUNDING_DATE,
      VOB,
      INTERMEDIARY_NUMBER,
      ASSET_CONSULTANT,
      DEPARTMENT
    ],
    rules: {
      [ASSET_CONSULTANT_NUMBER]: 'required|digits:6'
    },
    setUpFeeValues: [0]
  },

  faq: [FAQLinks.DEBTORS, FAQLinks.ENCASHMENT],

  documents: {
    masterData: [
      {
        title: i18n.t('masterData.dataProtectionRegulation'),
        path: '/assets/masterData/APL/FTY_Datenschutzhinweise_Art.13 14_092024.pdf'
      },
      {
        title: i18n.t('masterData.contractObligations'),
        path: '/assets/masterData/DAA/FTY_Vertragspflichten FSO 360 CASH_2023-08.pdf'
      },
      {
        title: i18n.t('masterData.purchaseRules'),
        path: '/assets/masterData/DAA/FTY_Ankaufregeln FSO CASH_2023-08.pdf'
      },
      {
        title: i18n.t('masterData.infoChangeBankDetails'),
        path: '/assets/masterData/APL/Bankdatenänderung_FTY_2024.pdf'
      }
    ],

    sepa: {
      template: {
        title: i18n.t('permanentDebts.pdf.sepaTemplate'),
        path: '/assets/sepa/Vorlage_Einzugsermächtigung.pdf'
      }
    },

    upload: {}
  },

  translations: {
    onboarding: {
      assetConsultantNumber: '@:onboarding.intermediaryNumberFull',
      assetConsultantData: '@:onboarding.intermediaryData'
    },
    historyEvents: {
      billing: {
        purchaseStatusWasMarkedAsPurchasable: 'Ankaufzusage',
        purchaseStatusWasMarkedAsNotPurchasable:
          'Der Beleg ist von der Ankaufzusage ausgeschlossen',
        voucherWasPurchased: 'Der Beleg wurde angekauft',
        voucherWasPurchasedLater: 'Der Beleg wurde angekauft',
        purchaseStatusWasMarkedAsManualPurchaseList: 'Der Beleg wurde angekauft',
        purchaseStatusWasMarkedAsNotPurchasableAnymore:
          'Der Beleg ist von der Ankaufzusage ausgeschlossen',
        purchaseStatusWasMarkedAsCountedBack: 'Der Belegankauf wurde rückabgewickelt'
      }
    }
  }
};

export default DAA_BILLING_CONFIG;
