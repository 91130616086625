import TutorialsMixin from '@/mixins/TutorialsMixin';
import { isValid, parseISO, endOfDay, startOfDay } from 'date-fns';

export const infoDialogStorage = 'user-information';

export default {
  mixins: [TutorialsMixin],

  computed: {
    infoDialogs() {
      /**
       * @type {object[]}
       * array of config objects with following properties
       * @property {String} key - unique key used to save confirmation in local storage
       * @property {String} title - card title
       * @property {String} text - card text
       * @property {Boolean} isAlert - whether to show error icon in title
       * @property {Number} startDate  - date in format yyyy-MM-dd (or with specific hour/minute: yyyy-MM-ddThh:mm+02:00 in summer or +01:00 in winter time), first day to show dialog
       * @property {Number} endDate - date in format yyyy-MM-dd (or with specific hour/minute: yyyy-MM-ddThh:mm+02:00 in summer or +01:00 in winter time), last day to show dialog
       * @property {Boolean} condition - set to false if dialog should not be displayed
       */
      return [
        {
          key: '2024-12-16-service-times-christmas',
          title: 'Erreichbarkeit um Weihnachten und Silvester',
          text: `<q>Same procedure as every year</q>, heißt es in dem hierzulande bekanntesten Silvester-Sketch <cite>Dinner for One</cite>. In diesem Sinne möchten wir Sie auch in diesem Jahr über unsere Erreichbarkeit um die Weihnachtsfeiertage und Silvester informieren.

            In den beiden Feiertagswochen erreichen Sie uns an folgenden Tagen von <b>7:00 Uhr bis 16:00 Uhr</b>:

            <ul>
             <li>Montag, den <b>23. Dezember</b></li>
             <li>Freitag, den <b>27. Dezember</b></li>
             <li>Montag, den <b>30. Dezember</b></li>
             <li>Donnerstag, den <b>2. Januar</b></li>
             <li>Freitag, den <b>3. Januar</b></li>
            </ul>
            Bitte beachten Sie, dass auch die Belegverarbeitung nur in gleichem zeitlichem Rahmen erfolgen wird.

            Ab dem 6. Januar stehen wir Ihnen wieder zu den Ihnen bekannten Servicezeiten zur Verfügung.

            Wir wünschen Ihnen und Ihren Angehörigen erholsame Weihnachtsfeiertage und einen guten Start in ein gesundes und erfolgreiches neues Jahr!

            Ihr Kundenservice`,
          startDate: '2024-12-16T06:00+01:00',
          endDate: '2025-01-03T18:00+01:00'
        }
      ];
    },
    activeInfoDialogs() {
      return this.infoDialogs.filter((dialog) => {
        const hasFulfilledCondition = this.hasFulfilledCondition(dialog);

        const startDateIsInPast =
          !dialog.startDate ||
          (isValid(parseISO(dialog.startDate)) &&
            (dialog.startDate.length > 10
              ? Date.parse(dialog.startDate)
              : startOfDay(Date.parse(dialog.startDate))) < Date.now());

        const endDateIsInFuture =
          !dialog.endDate ||
          (isValid(parseISO(dialog.endDate)) &&
            (dialog.endDate.length > 10
              ? Date.parse(dialog.endDate)
              : endOfDay(Date.parse(dialog.endDate))) > Date.now());

        return hasFulfilledCondition && startDateIsInPast && endDateIsInFuture;
      });
    }
  }
};
